
import React  from 'react';
import VandyplotApp from './VandyplotApp';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import './App.css';

class App extends React.Component {

  // constructor(props) {
  //   super(props);
  // }

  render(){
    return(
      <Route path={["/:geneName", "/"]}>
        <VandyplotApp />
      </Route>
    )
  }
}
export default withRouter(App);